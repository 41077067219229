<template>
  <!--表格列表拖拽排序弹窗-->
  <el-dialog title="自定义列表项" :visible.sync="setShow" width="60%" :before-close="handleClose">
    <div>
      <div class="customList">
        <!--main-->
        <table>
          <thead>
          <tr class="b_b">
            <td class="f_w">显示</td>
            <td class="f_w">列名</td>
            <td class="f_w">拖动调整顺序</td>
          </tr>
          </thead>
          <draggable v-model="tablelist" element="tbody" :move="getdata" @update="datadragEnd">
            <tr v-for="(item,index) in tablelist" :key="index" class="b_b">
              <td>
                <el-checkbox v-model="item.enabled"></el-checkbox>
              </td>
              <td>{{item.recordName}}</td>
              <td><img src="../../assets/img/icon_move.png"></td>
            </tr>
          </draggable>
        </table>
      </div>
      <div class="flex_b_c">
        <div class="blue pointer" v-on:click="updateDefault()">恢复默认</div>
        <span slot="footer" class="dialog-footer">
          <el-button size="small" @click="robackColunm()">取 消</el-button>
          <el-button size="small" type="primary" @click="commitColunm()">确 定</el-button>
        </span>
      </div>
    </div>
  </el-dialog>
</template>

<script>
  import draggable from "vuedraggable";

  export default {
    name: "draggablePop",
    components: {draggable},
    props: {
      moduleId: Number,
      setShow: Boolean,
    },
    data() {
      return {
        tablelist: [],   // 列表每列名称数据
        tablelist2:[],
        commitState: false,
      }
    },
    mounted() {
      // 页面加载调用方法
      this.getColumnList();
    },
    methods: {
      // 请求列表数据
      getColumnList() {
        this.commitState = false
        let that = this;
        this.$axios(this.api.zlzs.querySysOriginalDescriptionAll, {
          flag:this.moduleId,
          onDetails:1
        }, 'get').then(data => {
          if (data) {
            that.tablelist = data
            that.tablelist.forEach(function (v) {
              v.fieldEnname=that.toHump(v.fieldEnname)
              if(v.fieldEnname=='updateId'){
                v.fieldEnname = 'updateName'
              }
              if(v.fieldEnname=='createId'){
                v.fieldEnname = 'createName'
              }
              let enabled = v.onShow == 0 ? true: false;
              that.$set(v,"enabled",enabled)
            })
            that.tablelist2 = JSON.parse(JSON.stringify(that.tablelist))
            // that.$emit('getTbList', that.tablelist, this.setShow);

            // console.log("修改配置：",that.tablelist);
          }
        })
      },
      toHump(name) {
        return name.replace(/\_(\w)/g, function(all, letter){
          return letter.toUpperCase();
        });
      },
      // 确认修改排序列
      commitColunm() {
        let i = 0
        this.tablelist.forEach(function (item, index) {
          if(item.enabled){
            i+=1
          }
        })
        if(i<=5){
          this.$message.warning("勾选项不能小于等于5！")
          this.getColumnList();
          return
        }
        this.$confirm(`确认修改排序列吗?`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          let that = this;
          this.commitState = true
          this.tablelist.forEach(function (item, index) {
            item.sortnumber = index;
            item.onShow = item.enabled=== true ? 0: 1;
          })
          that.$emit('getTbList', that.tablelist, false);
          this.$message.success("保存成功！")
        })

      },
      // 取消修改排序列
      robackColunm() {
        // this.getColumnList();
        this.$emit('getTbList', this.tablelist, false);
      },
      //恢复默认修改排序列
      updateDefault() {
        this.$confirm(`确定恢复默认吗?`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.getColumnList();
          // this.$emit('getTbList', this.tablelist2, false);
        })


      },
      // 设置拖动排序
      getdata(evt) {
      },
      datadragEnd(evt) {
        console.log(evt);
      },
      // 关闭
      handleClose() {
        this.$emit('getTbList', this.tablelist, false);
      },
    },
  }
</script>

<style scoped>

</style>
