<template>
    <el-dialog title="添加权限用户" width="80%" :close-on-click-modal="false" append-to-body :visible.sync="visible">
        <el-row :gutter="20">
            <el-col :span="12">
                <div class="b_t b_r b_l b_bot">
                    <div class="flex_l_c b_b p_all1">
                        <el-input style="width: 60%" class="m_r1" maxlength="66" size="small" v-model="searchStr" placeholder="请输入关键字进行搜索" clearable></el-input>
                        <el-button type="primary" @click="searchRefreshList()" size="small" icon="el-icon-search">搜索</el-button>
                    </div>
                    <div class="flex_b_c b_b" v-for="(item, index) in userList" :key="item.id">
                        <div class="p_all1">
                            <span class="el-icon-user-solid m_r1"></span>
                            <span>{{item.name}}</span>
                        </div>
                        <div class="p_all1 b_l">
                            <el-button type="primary" @click="toLeft(item, index, 0)" size="mini" icon="el-icon-right"></el-button>
                        </div>
                    </div>
                </div>
                <el-pagination
                        @size-change="sizeChangeHandle"
                        @current-change="currentChangeHandle"
                        :current-page="pageNo"
                        :page-sizes="[10, 20, 50, 100]"
                        :page-size="pageSize"
                        :total="total"
                        background
                        layout="total, sizes, prev, pager, next, jumper">
                </el-pagination>
            </el-col>
            <el-col :span="12">
                <div class="b_t b_r b_l">
                    <div class="b_b" style="padding: 15px">权限用户列表</div>
                    <div class="flex_b_c b_b p_all1" v-for="(item, index) in authorityList" :key="item.id">
                        <div>
                            <span class="el-icon-user-solid m_r1"></span>
                            <span>{{item.name}}</span>
                        </div>
                        <el-button icon="el-icon-delete" size="mini" type="danger"  @click="toLeft(item, index, 1)">删除</el-button>
                    </div>
                </div>
            </el-col>
        </el-row>
        <span slot="footer" class="dialog-footer">
            <el-button size="small" @click="visible = false">取消</el-button>
            <el-button size="small" type="primary" @click="doSubmit()" v-noMoreClick>保存</el-button>
        </span>
    </el-dialog>
</template>

<script>
    export default {
        data () {
            return {
                visible: false,
                searchStr: '',
                userList: [],
                authorityList: [],
                files:[],
                pageNo: 1,
                pageSize: 10,
                total: 0,
                id:""
            }
        },
        methods: {
            init(file,id) {
                this.authorityList = [];
                this.id = id
                this.files = [];
                if(id == 0){
                    this.files = file;
                }else{
                    let file2 = {
                        id:id
                    }
                    // this.$set(file2,"id",id)
                    this.files.push(file2)
                }
                this.pageNo = 1
                this.refreshList(id,true);
                this.visible = true
            },
            searchRefreshList(){
                this.pageNo = 1
                this.refreshList(this.id,true)
            },
            refreshList(id,flag) {
                this.loading = true
                let that = this;
                this.$axios(this.api.user.sysuserQueryRoleUserList, {
                    'current': this.pageNo,
                    'size': this.pageSize,
                    'orderBy': '',
                    "name":this.searchStr,
                    loginName: '',
                }, 'get').then(data => {
                    if (data && data.status) {
                        this.userList = data.data.records
                        this.total = parseInt(data.data.total)
                        this.loading = false

                        if(id!=0 && flag ){
                            that.$axios(that.api.common.authorityById, {
                                'fileId': id,
                            }, 'get').then(data => {
                                if (data && data.status) {
                                    this.authorityList = [];
                                    that.authorityList = data.data;
                                }
                            })
                        }

                    }
                })
            },

            // 移到右边
            toLeft(item, index, type) {
                if(type == 0) {
                    let pd = false
                    this.authorityList.forEach(function (v) {
                        if(item.id == v.id){
                            pd = true
                        }
                    })
                    if(pd){
                        this.$message.error("已经在用户列表")
                        return false
                    }
                    this.authorityList.push(item)
                } else {
                    this.$confirm(`确定删除这条数据吗?`, "提示", {
                        confirmButtonText: "确定",
                        cancelButtonText: "取消",
                        type: "warning",
                    }).then(() => {
                        this.authorityList.splice(index, 1)
                    })
                }
            },
            // 保存
            doSubmit () {
                if(this.id==0 && this.authorityList.length==0){
                    this.$message.error("请添加权限用户")
                    return
                }
                let userIds = this.authorityList.map(item => {
                    return item.id
                }).join(',')
                let fileIds = this.files.map(item => {
                    return item.id
                }).join(',')
                let api = this.api.zlzs.managementSave
                if(this.id!=0){
                    api =  this.api.zlzs.managementSave2
                }
                this.$axios(api, {
                    "fileIds":fileIds,
                    "users":userIds,
                }, "post").then(data => {
                    this.loading = false
                    if (data && data.status) {
                        this.$message.success(data.msg)
                        this.visible = false
                    }else{
                        this.$message.error(data.msg)
                    }
                })
            },
            // 每页数
            sizeChangeHandle(val) {
                this.pageSize = val
                this.pageNo = 1
                this.refreshList(this.id,false)
            },
            // 当前页
            currentChangeHandle(val) {
                this.pageNo = val
                this.refreshList(this.id,false)
            },
        }
    }
</script>

<style scoped>

</style>
